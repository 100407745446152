<template>
  <v-container>
    <v-col>
      <v-row justify="center">
        <v-col md="9" lg="9" sm="12">
          <s-add-person :full="true" @search="search" @save="save" />
        </v-col>
        <v-col md="3" lg="3" sm="12">
          <v-img style="border-radius:10px;" height="440" :src="image" />
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import sAddPerson from "@/components/Utils/Person/sAddPerson";
import _service from "@/services/HelperService";
export default {
  components: { sAddPerson },

  data() {
    return {
      person: null,
      image: null,
    };
  },
  methods: {
    search(value) {
      if (value != null) {
        if (value.GenPerson.PrsDocumentNumber.length > 0)
          _service.getPhotoWorker(value.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((rsp) => {
            const blob = new Blob([rsp.data], {
              type: "image/jpg",
            });

            let image = blob;
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
              this.image = reader.result;
            };
          });
        else this.image = null;
      } else {
        this.image = null;
      }
    },
    save() {
      this.image = null;
    },
  },
};
</script>
